<template>
  <v-container grid-list-md>
    <v-layout>
      <v-flex>
        <v-card :loading="loading">
          <div
            class="primary pa-3 d-flex justify-space-between align-center white--text"
            color="primary"
          >
            <div>
              <h1 class="text-h5">{{administrator.name}}</h1>
              <h2 class="mt-0 text-h6 font-weight-light">CNPJ: {{administrator.document}}</h2>
            </div>
          </div>
          <!-- <v-card-text class="py-0">
            <v-row>
              <v-col class="py-0">
                <v-flex class="mt-1">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td>
                          Patrimônio
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon color="primary" small dark v-on="on">mdi-information-outline</v-icon>
                            </template>
                            <span>Patrimônio estimado (Soma de todos os fundos)</span>
                          </v-tooltip>
                        </td>
                        <td>
                          {{currencyFormat(administrator.equity) || '--'}}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-flex>
              </v-col>
              <v-col class="py-0">
                <v-flex class="mt-1">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td>Total de fundos</td>
                        <td>
                          {{funds.length || '--'}}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-flex>
              </v-col>
            </v-row>
          </v-card-text> -->
          <v-text-field
            v-model="filter"
            class="px-3"
            append-icon="mdi-search"
            label="Filtrar fundos"
            single-line
            hide-details
          ></v-text-field>
           <v-data-table
            :headers="headers"
            :items="funds"
            sort-by="calories"
            :loading="loading"
            class="color-table"
            :items-per-page="20"
            :search="filter"
          >
            <template v-slot:item.name="{ item }">
              <router-link :to="`/funds/${item._id}`">{{item.name}}</router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style lang="scss">
.return-date {
  border-bottom: dotted 1px #333;
  cursor: pointer;
}
.color-table {
  tbody {
    tr {
      a {
        display: block;
        text-decoration: none;
        color: #000;
      }
    }
  }
}
</style>

<script>
import api from '@/services/api';

export default {
  data: () => ({
    administrator: {},
    loading: true,
    funds: [],
    filter: '',
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'Documento', value: 'document' },
    ],
  }),

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      this.id = this.$route.params.id;
      const { data } = await api.administrators.byIds([this.id]);
      this.administrator = data?.[0];
      this.funds = []; // data.administrator.funds doesn't exists
      this.loading = false;
    },

    currencyFormat(num) {
      return `R$${Number(num).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
    },
  },
};
</script>
